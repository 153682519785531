.board {
}

.board__chunk {
    position: relative;
}

.board__chunkResizer {
    top: auto !important;
    bottom: 0;
    z-index: 40;
}

.board__chunk_resizing {

}

.board__chunkResizer_resizing {

}
