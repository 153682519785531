.contentItem {
  --width-value: calc(max(var(--main-width) / 3 - 2/3 * 16px, 210px));
  min-width: var(--width-value);
  max-width: var(--width-value) !important;
}

@media screen and (min-width: 1024px) {
  .contentItem {
    --width-value: calc(max(var(--main-width) / 6 - 5/6 * 16px, 210px));
    min-width: var(--width-value);
    max-width: var(--width-value) !important;
  }
}

.contentItem:hover .contentItem__preview {
  opacity: 1;
}

.contentItem_transition .contentItem__preview {
  filter: none;
  opacity: 1;
}
