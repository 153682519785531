.cell {
    position: relative;
    outline: none !important;
    /*flex-grow: 1;*/
    /*transition: width .0s;*/
}

.cell[data-dragging] {
    opacity: 0.6;
}

.cell__content {

}

.cell_resizing {
    transition: opacity .15s;
}

.cell__resizer {
    right: -1px;
    left: auto !important;
    transform: translateX(100%);
}
