.row {
  position: relative;
  width: 100%;
  /*width: calc(100% + 24px);*/
  display: flex;
  /*margin-left: -24px;*/
}

.row_resizing {
  overflow: hidden;
}

.row > div:not([data-drop-target]) {
  border: 1px solid theme(colors.gray.200);
  border-radius: 0.25rem;
}

.row > div:not([data-drop-target]) + div:not([data-drop-target]) {
  margin-left: theme(spacing.6);
}

.row > div[data-drop-target] {
  border-radius: 0.25rem;
  width: theme(spacing.6);
  position: relative;
  z-index: 10;
}

.row > div[data-drop-target] > div {
  background: theme('colors.primary.500');
  border-radius: theme(borderRadius.sm);
  width: 2px;
  height: 100%;
  margin: auto;
}

.row > div[data-drop-target]:first-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(0px - theme(spacing.6));
}

.row > div[data-drop-target]:last-child {
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(0px - theme(spacing.6));
}

@media screen and (max-width: 600px) {
  .row > div:not([data-drop-target]):not(:last-child) {
    margin-right: theme(spacing.4);
  }
  .row > div[data-drop-target] {
    left: calc(0px - theme(spacing.4) / 2);
  }
  .row > div[data-drop-target]:first-child {
    left: calc(0px - theme(spacing.4) / 4);
  }
  .row > div[data-drop-target]:last-child {
    right: calc(0px - theme(spacing.4) / 2);
  }
}

@media screen and (max-width: 1023px) {
  .row > div:not([data-drop-target]) + div:not([data-drop-target]) {
    margin-left: 0;
    margin-top: theme(spacing.4);
  }
}
