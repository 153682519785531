.resizer {
  position: absolute;
  top: 0;
  left: 0;
}

.resizer::after {
  content: '';
  border-radius: 0.25rem;
  background: theme('colors.primary.500');
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s 0.25s;
}

.resizer:hover::after {
  transition: opacity 0.15s 0s;
  opacity: 0.75;
}

.resizer_resizing::after,
.resizer_resizing:hover::after {
  opacity: 1;
}

.resizer_horizontal {
  cursor: col-resize;
  height: 100%;
  width: theme(spacing.6);
}

.resizer_horizontal::after {
  height: 100%;
  width: 3px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.resizer_vertical {
  cursor: row-resize;
  width: 100%;
  height: theme(spacing.6);
}

.resizer_vertical::after {
  width: 100%;
  height: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
