.placeholder {
  width: 100%;
  position: relative;
}
.placeholder_resizing {
}

.placeholder__row {
  composes: row from '../BoardRow/BoardRow.module.css';
  height: theme(spacing.6);
}

div.placeholder:first-child > .placeholder__row {
  height: theme(spacing.8);
}

div.placeholder > .placeholder__row > div[data-drop-target] {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
}

div.placeholder:first-child > .placeholder__row > div[data-drop-target] {
  left: 0;
  right: 0;
  width: 100%;
  top: auto;
  bottom: 12px;
}

div.placeholder .placeholder__row > div:not([data-drop-target]),
div.placeholder .placeholder__row > div[data-drop-target] {
  border: none;
}

.placeholder .placeholder__row > div[data-drop-target] {
  background: theme('colors.primary.500');
  border-radius: 0.25rem;
}

@media screen and (max-width: 600px) {
  .placeholder__row {
    height: theme(spacing.4);
  }
}
